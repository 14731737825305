<template lang="pug">
  .prorgessbar
    .label {{ label }}
    .progress-indicator(:style="progressIndicatorStyle")
</template>

<script>
  export default {
    props: {
      value: {
        type: Number,
        required: true
      },
      label: {
        type: String,
        default: undefined
      },
      max: {
        type: Number,
        default: 100
      }
    },

    computed: {
      calculatedCurrentPercent() {
        return (this.value * 100) / this.max
      },

      progressIndicatorStyle() {
        return {
          width: `${this.calculatedCurrentPercent}%`
        }
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  $progressbar-height: 25px

  .prorgessbar
    background-color: transparentize($default-purple, 0.76)
    border-radius: 10.5px
    height: $progressbar-height
    overflow: hidden
    transition: 0.5s
    width: 260px
    position: relative

    .progress-indicator
      +gradiented($default-purple-gradient 50% 50%)
      height: $progressbar-height

    .label
      position: absolute
      color: $default-white
      display: flex
      top: 0
      justify-content: center
      align-items: center
      left: 0
      bottom: 0
      right: 0
      text-shadow: 0px 0px 8px $default-black
      font-size: 0.6rem
</style>
